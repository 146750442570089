import React from 'react';

const UxLink = ( props:any ) => {
    const { width, url, label, text } = props;

    const w = width + 'px';

    return (
        <li style={{width:w}}>
            <div className="ux-link-bg" style={{backgroundColor:'#00b2ff'}}></div>
            <a href={url} className="ux-link-text" style={{color:'#00b2ff'}} target="_blank">{label}</a>
        </li>
    )
}

export default UxLink;

