import React from 'react';

const UxTitle = ( props:any ) => {
    const { title, designer, developer } = props;

    return (
        <div className="ux-title">
            <div>{title}</div>
            <div className="ux-roles">
            { designer ?
                <div>
                    <i className="fa fa-sharp fa-regular fa-pen-paintbrush" style={{color:'#00b2ff'}}></i> UX Designer
                </div>
                : <></>
            }
            { developer ?
                <div>
                    <i className="fa fa-sharp fa-regular fa-display-code" style={{color:'#00b2ff'}}></i> Developer
                </div>
                : <></>
            }
            </div>
        </div>
    )
}

export default UxTitle;

