import React, { useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import './App.scss';

import initials from './assets/img/awblock.png';
import milo from './assets/img/milo.png';
import milo1 from './assets/img/milo-1.jpg';
import milo2 from './assets/img/milo-2.jpg';
import milo3 from './assets/img/milo-3.jpg';

import adp from './assets/logos/adp.png';
import cmu from './assets/logos/cmu.png';
import delallo from './assets/logos/delallo.png';
import gianteagle from './assets/logos/gianteagle.png';
import guttman from './assets/logos/guttman.png';
import highmark from './assets/logos/highmark.png';
import ppg from './assets/logos/ppg.png';
import scoobi from './assets/logos/scoobi.png';
import swarovski from './assets/logos/swarovski.png';
import ten from './assets/logos/ten.png';
import upmc from './assets/logos/upmc.png';
import vector from './assets/logos/vector.png';
import wvmn from './assets/logos/wvmn.png';
import accd from './assets/logos/accd.png';
import ahn from './assets/logos/ahn.png';
import ati from './assets/logos/ati.png';
import heinz from './assets/logos/heinz.png';
import imgpgh from './assets/logos/imgpgh.png';
import msc from './assets/logos/msc.png';
import pdp from './assets/logos/pdp.png';
import steelers from './assets/logos/steelers.png';
import uss from './assets/logos/uss.png';

import clockwise from './assets/screens/clockwise.jpg';
import accomods from './assets/screens/accomods.jpg';
import asthma from './assets/screens/upmc-asthma.jpg';
import deli from './assets/screens/deli.jpg';
import empire from './assets/screens/empire.jpg';
import catalyst from './assets/screens/catalyst.jpg';
import whittaker from './assets/screens/whittaker.jpg';
import levin from './assets/screens/levin.jpg';
import vecint from './assets/screens/vector.jpg';
import ctrack from './assets/screens/ctrack.jpg';
import insulwise from './assets/screens/insulwise.jpg';
import getgo from './assets/screens/getgo.jpg';
import dompost from './assets/screens/dompost.jpg';
import commet from './assets/screens/commet.jpg';
import scoobiapp from './assets/screens/scoobi.jpg';
import baptist from './assets/screens/baptist.jpg';
import tac from './assets/screens/tac.jpg';
import notme from './assets/screens/notme.jpg';
import knowtifi from './assets/screens/knowtifi.jpg';
import swarv from './assets/screens/swarovski.png';
import woodbed from './assets/screens/woodbed.jpg';
import teslin from './assets/screens/teslin.jpg';
import tlc from './assets/screens/tlc.jpg';
import php from './assets/screens/phpassport.jpg';

import UxTitle from './UxTitle';
import UxLink from './UxLink';

function App() {

    const [menuOpen, setMenuOpen] = useState( false );
    const [moreResume, setMoreResume] = useState( false );

    const showLessResume:any = useRef(null);
    const about:any = useRef(null);
    const experience:any = useRef(null);
    const portfolio:any = useRef(null);
    const contact:any = useRef(null);
    const executeScroll = () => showLessResume.current.scrollIntoView({ behavior: "smooth" });

    const showLess = () => {
        setMoreResume(false);
        executeScroll();
    }

    const scrollTo = ( ref:any ) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
        setMenuOpen(false);
    }

    const responsive = {
        0: { items: 2 },
        650: { items: 3 },
        1100: { items: 4 },
        1500: { items: 5 },
    };

    const items = [
        <div className="item"><img src={adp} alt="ADP" /></div>,
        <div className="item"><img src={steelers} alt="Pittsburgh Steelers" /></div>,
        <div className="item"><img src={ahn} alt="Allegheny Health Network" /></div>,
        <div className="item"><img src={cmu} alt="Carnegie Mellon University" /></div>,
        <div className="item"><img src={delallo} alt="DeLallo Foods" /></div>,
        <div className="item"><img src={gianteagle} alt="Giant Eagle" /></div>,
        <div className="item"><img src={pdp} alt="Pittsburgh Downtown Partnership" /></div>,
        <div className="item"><img src={guttman} alt="Guttman Energy" /></div>,
        <div className="item"><img src={highmark} alt="Highmark" /></div>,
        <div className="item"><img src={ppg} alt="PPG" /></div>,
        <div className="item"><img src={scoobi} alt="Scoobi" /></div>,
        <div className="item"><img src={swarovski} alt="Swarovski Lighting" /></div>,
        <div className="item"><img src={ten} alt="The Efficiency Network" /></div>,
        <div className="item"><img src={upmc} alt="UPMC" /></div>,
        <div className="item"><img src={vector} alt="Vector Security" /></div>,
        <div className="item"><img src={wvmn} alt="West Virginia Metro News" /></div>,
        <div className="item"><img src={accd} alt="Allegheny Conference on Community Development" /></div>,
        <div className="item"><img src={ati} alt="ATI" /></div>,
        <div className="item"><img src={msc} alt="Marcellus Shale Coalition" /></div>,
        <div className="item"><img src={uss} alt="U.S. Steel" /></div>,
        <div className="item"><img src={heinz} alt="Heinz" /></div>,
        <div className="item"><img src={imgpgh} alt="ImaginePittsburgh.com" /></div>,
    ]

    useEffect( () => {
        scrollTo(about);
    }, []);

    return (
        <>
        <nav className={"left-nav" + (menuOpen ? ' open' : '')}>
            <div id="init-box" style={{backgroundColor:'#00b2ff'}}>
                <img src={initials} />
            </div>
            <h1>Alan Waldron</h1>
            <ul id="nav-ul" style={{borderRightColor:'#00b2ff'}}>
                <li id="nav-about"  className="on" onClick={()=>scrollTo(about)} style={{borderRightColor:'#00b2ff'}}>About</li>
                <li id="nav-resume" onClick={()=>scrollTo(experience)} style={{borderRightColor:'#00b2ff'}}>Experience</li>
                <li id="nav-portfolio" onClick={()=>scrollTo(portfolio)} style={{borderRightColor:'#00b2ff'}}>Portfolio</li>
                <li id="nav-contact" onClick={()=>scrollTo(contact)} style={{borderRightColor:'#00b2ff'}}>Contact</li>
            </ul>
        </nav>
            <nav className="top-nav">
                <div id="init-box" style={{backgroundColor:'#00b2ff'}}>
                    <img src={initials} />
                </div>
                <h1>Alan Waldron</h1>
                <div onClick={()=>setMenuOpen(!menuOpen)}>
                    <i className="fa fa-sharp fa-solid fa-bars" id="menu-toggle"
                       style={{color:'#00b2ff'}}></i>
                </div>
            </nav>
        <main>
            <section ref={about} id="sect-about">
                <div className="pct50">
                    <div id="alan-milo">
                        <img src={milo1} />
                        <img src={milo1} className="milo-pic" id="milo1" />
                        <img src={milo2} className="milo-pic hidden" id="milo2" />
                        <img src={milo3} className="milo-pic hidden" id="milo3" />
                        <div id="milo-hovers">
                            <div className="milo-hover" id="mhov1"></div>
                            <div className="milo-hover" id="mhov2"></div>
                            <div className="milo-hover" id="mhov3"></div>
                        </div>
                    </div>
                </div>
                <div className="pct50" id="about-alan">
                    <div>
                        <label>UX/Product Designer & Full Stack Developer</label>
                        <h1>Alan Waldron</h1>
                        <p className="hide1050">Over the past 25 years I’ve held many different roles including designer, developer, team lead, project manager and director for websites and software projects.</p>
                        <p>I specialize in User Experience / Product Design and Front-End Development in ReactJS and React Native. I am currently open to individual contributor and management roles in on-premise, remote or hybrid environments where my experience and expertise can make a meaningful impact to the business.</p>
                    </div>
                </div>
            </section>

            <section id="sect-whatido">
                <div className="pct100 pad50 what-i-do-do">
                    <div className="section-title" style={{borderBottomColor:'#00b2ff'}}>What I Do</div>
                    <div className="what-i-do">
                        <div>
                            <div><i className="fa fa-sharp fa-regular fa-pen-paintbrush" style={{color:'#00b2ff'}}></i></div>
                            <div>
                                <h2>UX & Product Design</h2>
                                <p>I've been designing software, mobile apps, web apps and websites that delight users and make stakeholders happy for over 25 years.</p>
                            </div>
                        </div>
                        <div>
                            <div><i className="fa fa-sharp fa-regular fa-display-code" style={{color:'#00b2ff'}}></i></div>
                            <div>
                                <h2>Full Stack Development</h2>
                                <p>Highly proficient front-end developer using HTML, CSS/SASS, ReactJS and React Native with a dash of Swift and Kotlin sprinked in.</p>
                            </div>
                        </div>
                        <div>
                            <div><i className="fa fa-sharp fa-regular fa-user-group" style={{color:'#00b2ff'}}></i></div>
                            <div>
                                <h2>Effective Leadership</h2>
                                <p>I lead teams of designers and developers to deliver projects on time and on budget.</p>
                            </div>
                        </div>
                        <div>
                            <div><i className="fa fa-sharp fa-regular fa-handshake-angle" style={{color:'#00b2ff'}}></i></div>
                            <div>
                                <h2>Client Management</h2>
                                <p>Very comfortable talking to clients and project stakeholders. I have a knack for relaying complex technical messaging in terms non-nerds can understand.</p>
                            </div>
                        </div>
                    </div>
                    <div className="section-title" style={{borderBottomColor:'#00b2ff'}}>Who I've Worked With</div>
                    <div >
                        <AliceCarousel
                            autoPlay={true}
                            autoPlayInterval={1500}
                            autoPlayStrategy={'none'}
                            disableDotsControls={true}
                            disableButtonsControls={true}
                            mouseTracking
                            items={items}
                            responsive={responsive}
                            controlsStrategy="alternate"
                            infinite={true}
                        />
                    </div>
                </div>
            </section>

            <section id="sect-resume" ref={experience} >
                <div className="pct100 pad50 resume">
                    <div className="section-title" style={{borderBottomColor:'#00b2ff'}}>Experience</div>

                    <div className="timeline">
                        <div className="timeline-entry">
                            <div className="timeline-date">
                                <b>2023 - 2024</b>
                                <p><a href="https://govini.com" target="_blank">Govini</a></p>
                                <i className="fa-solid fa-circle dot2"></i>
                                <i className="fa-regular fa-circle-dot dot1" style={{color:'#00b2ff'}}></i>
                            </div>
                            <div className="timeline-detail">
                                <b>Director of Product Design</b>
                                <ul>
                                    <li>Directed a team of product designers of Govini's flagship software product, Ark.ai, a SAAS platform that helps Department of Defense agencies manage supply chain risk.
                                    </li>
                                    <li>As a working manager of the team, 50% of my week was spent hands-on in Figma, delivering design leadership for high-profile projects.
                                    </li>
                                    <li>Established a design system in Figma, ensuring that all design outputs are consistent, accessible, and aligned with the product’s overall brand identity.
                                    </li>
                                    <li>Implemented Storybook into the engineering process, structuring and writing initial components.
                                    </li>
                                    <li>Worked closely with the Product Management and Engineering teams to align design goals to product strategy, ensuring the highest levels of quality and consistency across the platform.
                                    </li>
                                    <li>Developed and implemented a comprehensive design vision for Ark.ai, driving innovation and ensuring a seamless user experience that meets the needs of Dept of Defense clients.
                                    </li>
                                    <li>Championed a user-centric design approach, leveraging UX research and usability testing to guide design decisions and validate product features.
                                    </li>
                                    <li>Mentored and developed the design team, fostering a culture of continuous learning, creativity, and excellence in design.
                                    </li>

                                </ul>

                            </div>
                        </div>

                        <div className="timeline-entry" ref={showLessResume}>
                            <div className="timeline-date">
                                <b>2015 - 2024</b>
                                <p><a href="https://buildinmotion.com" target="_blank">Build in Motion</a></p>
                                <i className="fa-solid fa-circle dot2"></i>
                                <i className="fa-regular fa-circle-dot dot1" style={{color:'#00b2ff'}}></i>
                            </div>
                            <div className="timeline-detail">
                                <b>Partner</b>
                                <ul>
                                    <li>Responsible for business development, marketing, talent acquisition and project delivery.</li>
                                </ul>

                                <b>UX / Product Designer</b>
                                <ul>
                                    <li>Principal designer of all client software projects using the full suite of UX research, modeling and design processes such as competitive analyses, personas, affinity/empathy diagrams, journey maps, user scenarios/stories.</li>
                                    <li>Designs and prototypes created in Adobe UX and Figma.</li>
                                    <li>Frequently conducted first impression tests, essential task
                                        testing and user surveys.</li>
                                </ul>

                                <b>Full-Stack Developer</b>
                                <ul>
                                    <li>Developed all project user interfaces for web and mobile devices primarily using ReactJS and React Native.</li>
                                    <li>Developed REST APIs using NodeJS or .NET.</li>
                                    <li>Proficient data modeling for MongoDB and SQL databases.</li>
                                </ul>

                                <button id="show-more-resume" className={(moreResume ? 'hidden' : '')}
                                        onClick={()=>setMoreResume(true)}
                                        style={{backgroundColor:'#00b2ff'}}>Show More Positions</button>
                            </div>
                        </div>

                        <div id="more-resume" className={(moreResume ? 'resume-open' : '')}>

                            <div className="timeline-entry">
                                <div className="timeline-date">
                                    <b>2013 - 2015</b>
                                    <p><a href="https://pipitone.com" target="_blank">Pipitone</a></p>
                                    <i className="fa-solid fa-circle dot2"></i>
                                    <i className="fa-regular fa-circle-dot dot1" style={{color:'#00b2ff'}}></i>
                                </div>
                                <div className="timeline-detail">
                                    <b>Senior Interactive Strategist</b>
                                    <ul>
                                        <li>Wore many hats in the agency, including Designer, Developer, Project Manager, and Digital Marketing Manager.</li>
                                        <li>Worked with clients to define vision, goals, architecture, workflow and requirements for website projects.</li>
                                        <li>Designed intuitive and engaging website designs and user interfaces.</li>
                                        <li>Led development teams to ensure projects were delivered on time and budget.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="timeline-entry">
                                <div className="timeline-date">
                                    <b>2011 - 2013</b>
                                    <p>Vance Wright Adams</p>
                                    <i className="fa-solid fa-circle dot2"></i>
                                    <i className="fa-regular fa-circle-dot dot1" style={{color:'#00b2ff'}}></i>
                                </div>
                                <div className="timeline-detail">
                                    <b>Manager, Multimedia Projects</b>
                                    <ul>
                                        <li>Working manager of the web design and development team.</li>
                                        <li>Responsible for project requirements of client websites and interactive media projects, search engine optimization, digital marketing and PPC campaigns, as well as business development and client relationship management.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="timeline-entry">
                                <div className="timeline-date">
                                    <b>2004-2011</b>
                                    <p>Vocollect Inc.</p>
                                    <i className="fa-solid fa-circle dot2"></i>
                                    <i className="fa-regular fa-circle-dot dot1" style={{color:'#00b2ff'}}></i>
                                </div>
                                <div className="timeline-detail">
                                    <b>Sr. Applications Analyst, Collaboration</b>
                                    <ul>
                                        <li>Administrator of company intranet. Responsible for internal employee project collaboration, document management, discussions, corporate blogging, instant messaging and social communication.</li>
                                        <li>Led development of 25+ web applications to maintain product and customer data and improve internal workflows.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="timeline-entry">
                                <div className="timeline-date">
                                    <b>2002-2004</b>
                                    <p>Kennametal Inc.</p>
                                    <i className="fa-solid fa-circle dot2"></i>
                                    <i className="fa-regular fa-circle-dot dot1" style={{color:'#00b2ff'}}></i>
                                </div>
                                <div className="timeline-detail">
                                    <b>Web Development Analyst, E-Business</b>
                                    <ul>
                                        <li>Responsible for quality of Kennametal.com.</li>
                                        <li>Designed and developed UI of first company e-commerce portal consisting of over 300,000 products.</li>
                                        <li>Designed and developed two iterations of the company website. Led internationalization efforts publishing the website in Spanish, French, Italian and German.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="timeline-entry">
                                <div className="timeline-date">
                                    <b>1997-2001</b>
                                    <p>Top Rope Media</p>
                                    <i className="fa-solid fa-circle dot2"></i>
                                    <i className="fa-regular fa-circle-dot dot1" style={{color:'#00b2ff'}}></i>
                                </div>
                                <div className="timeline-detail">
                                    <b>Partner, Lead Designer / Developer</b>
                                    <ul>
                                        <li>Designer and programmer of a highly popular sports entertainment news website during the “dot-com bubble”.</li>
                                        <li>Responsible for design and programming of the website, marketing and promotion, partnerships, advertising sales, and management of staff of 25 writers.</li>
                                    </ul>
                                    <br/>
                                    <button id="show-less-resume" onClick={()=>showLess()}
                                            style={{backgroundColor:'#00b2ff'}}>Show Fewer Positions</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="sect-portfolio" ref={portfolio} >
                <div className="pct100 pad50 portfolio">
                    <div className="section-title" style={{borderBottomColor:'#00b2ff',marginBottom:'0px'}}>Portfolio</div>
                    <p style={{marginBottom:'50px'}}>The following is just a sample of the design work I've done over the past 10 years.</p>

                    <div className="ux">

                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={notme} alt="Not-Me" title="Not-Me" />
                            </div>
                            <div>
                                <UxTitle title={'Not-Me'} designer />
                                <div className="ux-description">
                                    <p>Not-Me is a new mobile application designed to facilitate consent between new sexual partners.</p>
                                    <p>Figma Password: "waldron"</p>
                                    <ul>
                                        <UxLink width={225} url="https://www.figma.com/design/60GiTfACfl2hoAHDGDT0Yg/NotMeApp?node-id=151-1737&t=8iDYC6PT6kuuQxTE-1" label="Figma - Not-Me Mobile App" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={scoobiapp} alt="Scoobi" title="Scoobi" />
                            </div>
                            <div>
                                <UxTitle title={'Scoobi'} designer developer />
                                <div className="ux-description">
                                    <p>Scoobi was a Pittsburgh-based ride share service that allowed people to rent street-legal riding mopeds and travel anywhere around the city.</p>
                                    <ul>
                                        <UxLink width={245} url="https://xd.adobe.com/view/07226eae-97c9-4a05-9314-585c4a6fca2a-5271/" label="XD Prototype - Initial App Flow" />
                                        <UxLink width={320} url="https://xd.adobe.com/view/25b7c4a1-f7d4-43de-a05e-ff7e2f3c7c98-70e4/" label="XD Prototype - Pre-Paid Minutes Feature" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={swarv} alt="Swarovski Infinite Aura" title="Swarovski Infinite Aura" />
                            </div>
                            <div>
                                <UxTitle title={'Swarovski Infinite Aura'} designer />
                                <div className="ux-description">
                                    <p>Swarovski Crystals needed an IOT-enabled mobile app to control their new line of lighting products, Infinite Aura. The app featured Swarovski's premium aesthetic and was used to control two different rings of LEDs within the fixture which allowed users to create unique lighting effects.</p>
                                    <ul>
                                        <UxLink width={185} url="/swarovski-design.pdf" label="PDF - Infinite Aura App Design" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={php} alt="Penn Hills Passport" title="Penn Hills Passport" />
                            </div>
                            <div>
                                <UxTitle title={'Penn Hills Passport'} designer developer />
                                <div className="ux-description">
                                    <p>Penn Hills Passport is a mobile app I designed and developed for my community. It's a news and events aggregator and community directory that helps to keep our 40,000 residents informed of everything going on in and around Penn Hills.</p>
                                    <ul>
                                        <UxLink width={250} url="https://apps.apple.com/us/app/penn-hills-passport/id6504437334" label="App Store - Penn Hills Passport" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={woodbed} alt="Woodbed" title="Woodbed" />
                            </div>
                            <div>
                                <UxTitle title={'Woodbed Delivery App'} designer developer />
                                <div className="ux-description">
                                    <p>Woodbed is a company that delivers bulk landscaping supply products. Their customers needed an app that live tracks deliveries, allows them to place orders for more product and pay off account balances.</p>
                                    <ul>
                                        <UxLink width={290} url="https://xd.adobe.com/view/ed380a01-93ce-4327-8825-1407b1e5f388-b86b/" label="UX Prototype - Woodbed Mobile App" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                       <div className="ux-item">
                            <div className="ux-screen">
                                <img src={knowtifi} alt="Knowtifi" title="Knowtifi" />
                            </div>
                            <div>
                                <UxTitle title={'Knowtifi'} designer developer />
                                <div className="ux-description">
                                    <p>Knowtifi is a mobile app that enhances the 911 safety experience. This Figma file lays out the user management piece of the app.</p>
                                    <p>Figma Password: "waldron"</p>
                                    <ul>
                                        <UxLink width={275} url="https://www.figma.com/design/IOq2t9FQm92Dtetg44grPo/User-Management?node-id=1-12&t=fD2dS8fg7KiTdF6g-1" label="Figma - Knowtifi User Registration" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={tlc} alt="The Laundry Chute" title="The Laundry Chute" />
                            </div>
                            <div>
                                <UxTitle title={'The Laundry Chute'} designer developer />
                                <div className="ux-description">
                                    <p>The Laundry Chute was an app-based laundry sevice used on college campuses. Using custom designed and developed locker system and a touchscreen interface, students would drop laundry off in the lockers and expect to pick up clean laundry within 24 hours.</p>
                                    <ul>
                                        <UxLink width={280} url="https://xd.adobe.com/spec/4ec844d1-8e3f-4c0c-adba-8d0342c736cd/specs/" label="XD Prototype - The Laundry Chute" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={tac} alt="Total Archery Challenge" title="Total Archery Challenge" />
                            </div>
                            <div>
                                <UxTitle title={'Total Archery Challenge'} designer />
                                <div className="ux-description">
                                    <p>Total Archery Challenge needed a ticketmaster-style queued registration experience for their events which attract thousands of participants per event.</p>
                                    <p>Figma Password: "waldron"</p>
                                    <ul>
                                        <UxLink width={215} url="https://www.figma.com/design/yrvO0Cq9r8cqGxn0pztDA2/Event-Registration-System?node-id=152-595&t=wopQAjzHZclZmxks-1" label="Figma - Event Registration" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={insulwise} alt="Insulwise" title="Insulwise" />
                            </div>
                            <div>
                                <UxTitle title={'Insulwise'} designer developer />
                                <div className="ux-description">
                                    <p>Insulwise is a company that specializes in home insulation and comfort. They needed an app that allowed their salespeople to spec out a job and present their customers with a contract quickly, while in their home, with offline capability. This is a desktop app that was developed using the Electron framework.</p>
                                    <ul>
                                        <UxLink width={225} url="https://xd.adobe.com/view/83aaa43e-a5ba-41a0-4543-21b5ac232bde-f59b/" label="XD Prototype - InsulPro App" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={teslin} alt="PPG Teslin" title="PPG Teslin" />
                            </div>
                            <div>
                                <UxTitle title={'PPG Teslin'} designer />
                                <div className="ux-description">
                                    <p>PPG's Teslin is a synthetic printing substrate that is highly durable, versatile, and waterproof. The website features the many features and applications of the product.</p>
                                    <ul>
                                        <UxLink width={175} url="https://teslin.com" label="Website - PPG Teslin" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={levin} alt="Levin Furniture" title="Levin Furniture" />
                            </div>
                            <div>
                                <UxTitle title={'Levin Furniture'} designer developer />
                                <div className="ux-description">
                                    <p>Levin Furniture wanted to make major enhancements to their customer portal, allowing them to manage everything from orders to deliveries, returns, service requests and more. </p>
                                    <ul>
                                        <UxLink width={255} url="https://xd.adobe.com/view/03798517-b87e-483c-b5d0-b4a68344a03c-562a/" label="XD Prototype - Customer Portal" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={accomods} alt="Accomods" title="Accomods" />
                            </div>
                            <div>
                                <UxTitle title={'Accomods'} designer developer />
                                <div className="ux-description">
                                    <p>Accomods is an application that allows teachers and parents to build IEPs and 504 plans for their students with special needs. The builder function walks a user through the student’s strengths and needs and then recommends a list of classroom accommodations and modifications (Accomods) for that student. From there the user can create a plan of any number of Accomods. A desktop version was prototyped but it was built responsively and works well on mobile.</p>
                                    <ul>
                                        <UxLink width={195} url="https://xd.adobe.com/view/e6ccd583-d51f-4e4a-7d59-4507a48710a6-90fd/screen/79da0bd7-8cf4-4714-b8cf-80ad70b7d7b0" label="XD Prototype - Desktop" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={clockwise} alt="Clockwise" title="Clockwise" />
                            </div>
                            <div>
                                <UxTitle title={'Clockwise'} designer />
                                <div className="ux-description">
                                    <p>Clockwise is a custom screen printing, digital printing and embroidery company. We were hired by them to re-imagine their online ordering process. They already had a development team so this was purely a UX project. </p>
                                    <ul>
                                        <UxLink width={195} url="https://xd.adobe.com/view/1e1872d7-721f-4ea0-ab01-bfbbdeeab7bf-4b4b/" label="XD Prototype - Desktop" />
                                        <UxLink width={185} url="https://xd.adobe.com/view/b3ff17fc-e74e-4ca4-b330-94512f6ec5d0-e6ac/" label="XD Prototype - Mobile" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={vecint} alt="Vector Security" title="Vector Security" />
                            </div>
                            <div>
                                <UxTitle title={'Vector Security'} designer />
                                <div className="ux-description">
                                    <p>Vector Security was looking to build an application that allowed system admins to create, manage and track API integrations between systems. The app would allow them to schedule when data streams should run, monitor when endpoints go down, and schedule maintenance to endpoints to ensure they do not run during those windows. </p>
                                    <ul>
                                        <UxLink width={225} url="https://xd.adobe.com/view/97e7bd06-5ae6-4bd2-a7e7-163b3bcfe253-c2b4/" label="XD Prototype - Integrations Manager" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/*<div className="ux-item">*/}
                        {/*    <div className="ux-screen">*/}
                        {/*        <img src={commet} alt="Commet" title="Commet" />*/}
                        {/*    </div>*/}
                        {/*    <div>*/}
                        {/*        <UxTitle title={'Commet'} designer developer />*/}
                        {/*        <div className="ux-description">*/}
                        {/*            <p>Commet is a geo-social community engagement platform that is currently in development. Prototypes here are early concepts for the app.</p>*/}
                        {/*            <ul>*/}
                        {/*                <UxLink width={215} url="https://xd.adobe.com/view/61e41e97-da27-49fa-a2ce-b1305a886fbd-34b7/" label="XD Prototype - Mobile App" />*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={empire} alt="Empire Music" title="Empire Music" />
                            </div>
                            <div>
                                <UxTitle title={'Empire Music'} designer />
                                <div className="ux-description">
                                    <p>Website design for a local guitar shop. A good example of some higher-end design I'm capable of when necessary.</p>
                                    <ul>
                                        <UxLink width={195} url="https://xd.adobe.com/view/6257c65a-c0f6-4cba-6eb9-901597020767-1466/" label="XD Prototype - Website" />
                                        <UxLink width={255} url="https://xd.adobe.com/view/aa42f1c9-10a0-40b8-5bbc-296de1051acb-dc73/" label="XD Prototype - Alt Product View" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={ctrack} alt="SMS Group C-Track" title="SMS Group C-Track" />
                            </div>
                            <div>
                                <UxTitle title={'SMS Group C-Track'} designer developer />
                                <div className="ux-description">
                                    <p>SMS Group is a company that manufactures and leases equipment used in the steeel making industry. Tracking the usage, or how much tonnage of steel, flows through their machinery is a vital metric to track. This drag & drop application enables SMS to track the amount of steel flowing through every component of their continuous steel casting equipment, allowing them to swap out components, add new components, schedule repair, and gather metrics on component lifecycles.</p>
                                    <ul>
                                        <UxLink width={195} url="https://xd.adobe.com/view/61513a25-a030-49a5-59af-c999c1f2f3d4-1760/" label="XD Prototype - C-Track" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={asthma} alt="UPMC Asthma Institute Participant Survey" title="UPMC Asthma Institute Participant Survey" />
                            </div>
                            <div>
                                <UxTitle title={'UPMC Asthma Institute Survey'} designer />
                                <div className="ux-description">
                                    <p>Title is self-explanatory. We were getting ready to start development on this project just as COVID hit and UPMC’s priorities changed a tad, so some screens have placeholders for forms.</p>
                                    <ul>
                                        <UxLink width={180} url="https://xd.adobe.com/view/baeecc7c-7cb6-4522-6ba8-ed24efc9355e-ccdf/" label="XD Prototype - Tablet" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={baptist} alt="The Baptist Standard" title="The Baptist Standard" />
                            </div>
                            <div>
                                <UxTitle title={'The Baptist Standard'} designer developer />
                                <div className="ux-description">
                                    <p>Baptist Standard Publishing is an independent, nonprofit media organization for Baptists in Texas. Their mobile app is a wrapper of their Wordpress website which allows them to manage their app content seamlessly with their website.</p>
                                    <ul>
                                        <UxLink width={215} url="https://xd.adobe.com/view/0f28dfe6-8ec3-45fd-849b-2e9b53cb1d5c-5a7b/" label="XD Prototype - Mobile App" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={whittaker} alt="Whittaker Country" title="Whittaker Country" />
                            </div>
                            <div>
                                <UxTitle title={'Whittaker Country'} designer developer />
                                <div className="ux-description">
                                    <p>Whittaker Country was a long-time client of Build in Motion, in this prototype, I propose a new layout of their e-commerce platform (that I also designed) for the purpos of an in-store touchscreen kiosk.</p>
                                    <ul>
                                        <UxLink width={270} url="https://xd.adobe.com/view/eaf8e42e-12ce-403d-8d3b-6c504047143d/" label="XD Prototype - Touchscreen Kiosk" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={dompost} alt="Dominion Post" title="Dominion Post" />
                            </div>
                            <div>
                                <UxTitle title={'Dominion Post'} designer developer />
                                <div className="ux-description">
                                    <p>Long time client, WV MetroNews acquired a local Morgantown newspaper called the Dominion Post. I was tasked with designing a Wordpress template that would work for both newspapers but could be themed differently for each.</p>
                                    <ul>
                                        <UxLink width={270} url="https://xd.adobe.com/view/229c9fec-4e2a-4757-41f3-35dde5c0c69c-54ad/" label="XD Prototype - Dom Post Desktop" />
                                        <UxLink width={260} url="https://xd.adobe.com/view/322feeb4-590a-4d4a-4df0-9ef6d416356d-441e/" label="XD Prototype - Dom Post Mobile" />
                                        <UxLink width={245} url="https://xd.adobe.com/view/55d689ff-225b-4224-7098-a3cb17b8f4fb-23e7/" label="XD Prototype - WVMN Desktop" />
                                        <UxLink width={235} url="https://xd.adobe.com/view/5f4bc2fe-6721-4655-4538-29540a72ddd4-297a/" label="XD Prototype - WVMN Mobile" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={catalyst} alt="Build in Motion Catalyst" title="Build in Motion Catalyst" />
                            </div>
                            <div>
                                <UxTitle title={'Build in Motion Catalyst'} designer developer />
                                <div className="ux-description">
                                    <p>Build in Motion has created its own Platform-as-a-Service that allows us to quickly create the infrastructure for our client projects, and also gives our clients the power to manage their applications and data on their own.</p>
                                    <ul>
                                        <UxLink width={225} url="https://xd.adobe.com/view/56024936-dc18-4355-bdb8-58fccade65e9-0351/" label="XD Prototype - Admin Panel" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={getgo} alt="Get-Go Touch" title="Get-Go Touch" />
                            </div>
                            <div>
                                <UxTitle title={'Get-Go Touch'} designer />
                                <div className="ux-description">
                                    <p>Prior to the pandemic we were working with Giant Eagle / Get Go to design a touch-screen ordering system. The project was cancelled at the start of the pandemic and Get-Go implemented an off-the-shelf solution.</p>
                                    <ul>
                                        <UxLink width={300} url="https://xd.adobe.com/view/6fbdb07a-70ff-4595-59e0-c2f414b02dbf-198c/" label="XD Prototype - Touchscreen Ordering" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ux-item">
                            <div className="ux-screen">
                                <img src={deli} alt="Giant Eagle Deli Dash" title="Giant Eagle Deli Dash" />
                            </div>
                            <div>
                                <UxTitle title={'Giant Eagle Deli Dash'} designer />
                                <div className="ux-description">
                                    <p>Again prior to the pandemic we were working with Giant Eagle to reimagine their deli ordering system. Allowing users to place their deli orders in different places in the store using touchscreen kiosks. Note this prototype is an early draft and also somehow lost its custom fonts.</p>
                                    <ul>
                                        <UxLink width={300} url="https://xd.adobe.com/view/8c8a3f47-7c90-4d7e-9f85-d34281c27215-d9d9/" label="XD Prototype - Touchscreen Ordering" />
                                        <UxLink width={285} url="https://xd.adobe.com/view/ec5768b3-2649-43a1-9a40-339e19c6c5ef-b904/" label="XD Prototype - Tap For Deli Number" />
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="sect-contact" ref={contact}>
                <div className="pct100 pad50 contact">
                    <div className="section-title" style={{borderBottomColor:'#00b2ff'}}>Contact</div>
                    <div id="contact-blocks">
                        <a href="tel:14124002934">
                            <i className="fa fa-sharp fa-light fa-phone" style={{color:'#00b2ff'}}></i>
                            <p>412-400-2934</p>
                        </a>
                        <a href="https://www.google.com/maps/@40.4313684,-79.9805005,12z?entry=ttu" target="_blank">
                            <i className="fa fa-sharp fa-light fa-location-dot" style={{color:'#00b2ff'}}></i>
                            <p>Pittsburgh, PA</p>
                        </a>
                        <a href="mailto:alan@alanwaldron.com">
                            <i className="fa fa-sharp fa-light fa-envelope" style={{color:'#00b2ff'}}></i>
                            <p>alan@alanwaldron.com</p>
                        </a>
                    </div>

                </div>
            </section>

            <section id="copyright">
                Copyright &copy;2024 Alan Waldron. All Rights Reserved.
            </section>

            <div id="milo">
                <img src={milo} />
            </div>

        </main>
        </>
    );
}

export default App;
